import { useState, useEffect } from "react";
import styles from "./Example.module.css";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, onClick }: Props) => {
    const wordLimit = 20;
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 480);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    const textWords = text.split(' ');
    const shouldTruncate = isMobile && textWords.length > wordLimit;
    const textToShow = shouldTruncate && !showMore
        ? textWords.slice(0, wordLimit).join(' ') + '...'
        : text;

    const toggleShowMore = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation();
        setShowMore(!showMore);
    };

    return (
        <div className={styles.example} onClick={() => onClick(value)}>
            <p className={styles.exampleText}>
                {textToShow}
                {shouldTruncate && (
                    <span className={styles.showMoreText} onClick={toggleShowMore}>
                        {showMore ? ' Show Less' : ' Show More'}
                    </span>
                )}
            </p>
        </div>
    );
};
