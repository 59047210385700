//
//

import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import React, { useState, useEffect } from "react";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
// import step1 from "../../assets/step1.svg";
// import step2 from "../../assets/step2.svg";
// import step3 from "../../assets/step3.svg";
// import step4 from "../../assets/step4.svg";
// import step5 from "../../assets/step5.svg";
import step1 from "../../assets/step1.png";
import step2 from "../../assets/step2.png";
import step3 from "../../assets/step3.png";
import step4 from "../../assets/step4.png";
import step5 from "../../assets/step5.png";

const steps = [
    { message: "Analyzing your prompt", image: step1 },
    { message: "Searching the corpus", image: step2 },
    { message: "Retrieving relevant chunks", image: step3 },
    { message: "Performing chunk re-ranking", image: step4 },
    { message: "Final answer generation", image: step5 }
];

export const AnswerLoading = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    useEffect(() => {
        if (currentStep < steps.length - 1) {
            const timer = setTimeout(() => setCurrentStep(currentStep + 1), 5000);
            return () => clearTimeout(timer);
        }
    }, [currentStep]);

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.waitingContainer} verticalAlign="space-between">
                {/* <AnswerIcon />*/}
                <img src={steps[currentStep].image} width="50px" height="50px" alt={`Step ${currentStep + 1}`} />
                <Stack.Item grow>
                    <p className={styles.waitingText}>
                        {steps[currentStep].message}
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
