import { Options16Filled, ArrowSync16Filled } from "@fluentui/react-icons";
import styles from "./RAIPanel.module.css";

interface Props {
    onAdjustClick?: () => void;
    onRegenerateClick?: () => void;
}

export const RAIPanel = ({ onAdjustClick, onRegenerateClick }: Props) => {
    return (
        <div className={styles.adjustInputContainer}>
            <div className={styles.adjustInput} onClick={onAdjustClick} title="Adjust">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.5 9.33333V10.6667H4.5V9.33333H0.5ZM0.5 1.33333V2.66667H7.16667V1.33333H0.5ZM7.16667 12V10.6667H12.5V9.33333H7.16667V8H5.83333V12H7.16667ZM3.16667 4V5.33333H0.5V6.66667H3.16667V8H4.5V4H3.16667ZM12.5 6.66667V5.33333H5.83333V6.66667H12.5ZM8.5 4H9.83333V2.66667H12.5V1.33333H9.83333V0H8.5V4Z"
                        fill="#3C3B49"
                    />
                </svg>

                {/* <span className={styles.adjustInputText}>Adjust</span> */}
            </div>
            <div className={styles.adjustInput} onClick={onRegenerateClick} title="Regenerate">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.166 5.33268L10.4993 7.99935H12.4993C12.4993 10.206 10.706 11.9993 8.49935 11.9993C7.82602 11.9993 7.18602 11.8327 6.63268 11.5327L5.65935 12.506C6.47935 13.026 7.45268 13.3327 8.49935 13.3327C11.446 13.3327 13.8327 10.946 13.8327 7.99935H15.8327L13.166 5.33268ZM4.49935 7.99935C4.49935 5.79268 6.29268 3.99935 8.49935 3.99935C9.17268 3.99935 9.81268 4.16602 10.366 4.46602L11.3393 3.49268C10.5193 2.97268 9.54601 2.66602 8.49935 2.66602C5.55268 2.66602 3.16602 5.05268 3.16602 7.99935H1.16602L3.83268 10.666L6.49935 7.99935H4.49935Z"
                        fill="#3C3B49"
                    />
                </svg>

                {/* <ArrowSync16Filled primaryFill="black" /> */}
                {/* <span className={styles.adjustInputText}>Regenerate</span> */}
            </div>
        </div>
    );
};
