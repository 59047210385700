import {
    AskRequest,
    AskResponse,
    ChatRequest,
    BlobClientUrlResponse,
    AllFilesUploadStatus,
    GetUploadStatusRequest,
    GetInfoResponse,
    ActiveCitation,
    GetWarningBanner,
    StatusLogEntry,
    StatusLogResponse,
    ApplicationTitle,
    GetTagsResponse,
    GetFileRequest,
    GetFileResponse,
    chatHistory,
    ChatDeleteRequest
} from "./models";

const base_url = "";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch(`${base_url}/ask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                useSemanticRanker: options.overrides?.useSemanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                user_persona: options.overrides?.userPersona,
                system_persona: options.overrides?.systemPersona,
                ai_persona: options.overrides?.aiPersona
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch(`${base_url}/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        signal: AbortSignal.timeout(720000),
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            conversation_id: options.conversationId,
            overrides: {
                use_semantic_reranking: options.overrides?.useSemanticRanker,
                use_enriched_embeddings: options.overrides?.useEnrichedEmbeddings,
                use_multi_vector_query: options.overrides?.useMultiVectorQuery,
                query_transformation_type: options.overrides?.queryTransformationKey,
                retriver_feature: options.overrides?.retriverFeature,
                conversation_id: options.conversationId,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                rag_evaluation: options.overrides?.enableCompositeScore,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                user_persona: options.overrides?.userPersona,
                system_persona: options.overrides?.systemPersona,
                ai_persona: options.overrides?.aiPersona,
                response_length: options.overrides?.responseLength,
                response_temp: options.overrides?.responseTemp,
                selected_folders: options.overrides?.selectedFolders,
                selected_tags: options.overrides?.selectedTags,
                selected_files: options.overrides?.selectedFiles,
                model_name: options.overrides?.modelName
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    return `${encodeURIComponent(citation)}`;
}

export async function getBlobClientUrl(): Promise<string> {
    const response = await fetch(`${base_url}/getblobclienturl`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: BlobClientUrlResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse.url;
}

export async function getAllUploadFiles(options: GetUploadStatusRequest): Promise<AllFilesUploadStatus> {
    const response = await fetch(`${base_url}/getalluploadstatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            timeframe: options.timeframe,
            state: options.state as string
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    const results: AllFilesUploadStatus = { statuses: parsedResponse };
    return results;
}

export async function getAllUploadStatus(options: GetUploadStatusRequest): Promise<AllFilesUploadStatus> {
    const response = await fetch(`${base_url}/getalluploadstatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            timeframe: options.timeframe,
            state: options.state as string
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    const results: AllFilesUploadStatus = { statuses: parsedResponse };
    return results;
}

export async function logStatus(status_log_entry: StatusLogEntry): Promise<StatusLogResponse> {
    var response = await fetch(`${base_url}/logstatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            path: status_log_entry.path,
            status: status_log_entry.status,
            status_classification: status_log_entry.status_classification,
            state: status_log_entry.state
        })
    });

    var parsedResponse: StatusLogResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: StatusLogResponse = { status: parsedResponse.status };
    return results;
}

export async function getInfoData(): Promise<GetInfoResponse> {
    const response = await fetch(`${base_url}/getInfoData`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const parsedResponse: GetInfoResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getWarningBanner(): Promise<GetWarningBanner> {
    const response = await fetch(`${base_url}/getWarningBanner`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const parsedResponse: GetWarningBanner = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getCitationObj(citation: string): Promise<ActiveCitation> {
    const response = await fetch(`${base_url}/getcitation`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            citation: citation
        })
    });
    const parsedResponse: ActiveCitation = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getAllUploadedFiles(folder_names: string[]): Promise<ActiveCitation> {
    const response = await fetch(`${base_url}/getfiles`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            folder_names: folder_names
        })
    });
    const parsedResponse: ActiveCitation = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getApplicationConfig(): Promise<ApplicationTitle> {
    const response = await fetch(`${base_url}/config/details`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: ApplicationTitle = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getRagScores() {
    const response = await fetch(`${base_url}/rag/scores`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getAllTags(): Promise<GetTagsResponse> {
    const response = await fetch(`${base_url}/getalltags`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    var results: GetTagsResponse = { tags: parsedResponse };
    return results;
}

export async function chatHistoryapi(id:any): Promise<chatHistory> {
    const response = await fetch(`${base_url}/chatHistory?conversationId=${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: chatHistory = { history: parsedResponse };
    return results;
}

export async function deleteChatThread(options: ChatDeleteRequest) {
    const response = await fetch(`${base_url}/deleteChat?conversationId=${options}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    });

    return;
    // const parsedResponse: any = await response.json();
    // console.log(parsedResponse)
    // if (response.status > 299 || !response.ok) {
    //     console.log(response);
    //     throw Error(parsedResponse.error || "Unknown error");
    // }

    // var results: chatHistory = {history: parsedResponse};
    //     return results;
}

export async function updateChatHistoryThreadTitle(title: any, id: any) {
    const response = await fetch(`${base_url}/updateconversation?conversationId=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            title: title
        })
    });

    const parsedResponse: any = await response.json();
    console.log(parsedResponse);
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: chatHistory = { history: parsedResponse };
    return results;
}

export async function feedback(options: any): Promise<any> {
    const response = await fetch(`${base_url}/feedback`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            feedback_type: options.feedback_type,
            user_comment: options.user_comment,
            conversation_id: options.conversation_id,
            message_id: options.message_id
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}
