import styles from "./UserChatMessage.module.css";
import { useState } from "react";
import { IconButton } from "@fluentui/react";

interface Props {
    message: string;
    onEditSubmit: (msg: any) => void;
    envConfig: any;
}

export const UserChatMessage = ({ message, onEditSubmit, envConfig }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState(message);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedMessage(message);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        onEditSubmit(editedMessage);
        setEditedMessage(message);
        // Here, you could also send the edited message to a server or update it in a state higher up.
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedMessage(message);
    };

    return (
        <div className={styles.container}>
            {isEditing ? (
                <div className={styles.editMessage}>
                    <textarea value={editedMessage} onChange={e => setEditedMessage(e.target.value)} />
                    <div className={styles.buttonContainer}>
                        <IconButton
                            style={{ color: "#3C3B49" }}
                            iconProps={{ iconName: "Checkmark" }}
                            title="Save"
                            ariaLabel="Save"
                            onClick={() => handleSaveClick()}
                        />
                        <IconButton
                            style={{ color: "#3C3B49" }}
                            iconProps={{ iconName: "Cancel" }}
                            title="Cancel"
                            ariaLabel="Cancel"
                            onClick={() => handleCancelClick()}
                        />
                    </div>
                </div>
            ) : (
                <div className={styles.message}>
                    <div className={styles.editIcon}  title="Edit the request" onClick={() => handleEditClick()} >
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Edit" }}
                            title="Edit the request"
                            ariaLabel="Edit the request"
                            onClick={() => handleEditClick()}
                        />
                    </div>
                    <div> {message} </div>
                </div>
            )}
        </div>
    );
};
