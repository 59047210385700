//  
//  

import { Button, ButtonGroup } from "react-bootstrap";
import { Label } from "@fluentui/react";

import styles from "./ResponseLengthButtonGroup.module.css";

interface Props {
    className?: string;
    onClick: (_ev: any) => void;
    defaultValue?: number;
    themeColor?: string;
}

export const ResponseLengthButtonGroup = ({ className, onClick, defaultValue, themeColor }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Label>Response length:</Label>
            <ButtonGroup className={`${styles.buttongroup ?? ""}`} onClick={onClick}>
                <Button style={{backgroundColor: `${defaultValue == 1024? `${themeColor}`:""}`, borderRadius: "5px 0px 0px 5px", flex:"1", height: "35px"}} id="Succinct" className={`${defaultValue == 1024? styles.buttonleftactive : styles.buttonleft ?? ""}`} size="sm" value={1024} bsPrefix='ia'>{"Succinct"}</Button>
                <Button style={{backgroundColor: `${defaultValue == 2048?`${themeColor}`:""}`, borderRadius: "0px", flex:"1", height: "35px"}} id="Standard" className={`${defaultValue == 2048? styles.buttonmiddleactive : styles.buttonmiddle ?? ""}`} size="sm" value={2048} bsPrefix='ia'>{"Standard"}</Button>
                <Button style={{backgroundColor: `${defaultValue == 3072? `${themeColor}`:""}`, borderRadius: "0px 5px 5px 0px", flex:"1", height: "35px"}} id="Thorough" className={`${defaultValue == 3072? styles.buttonrightactive : styles.buttonright ?? ""}`} size="sm" value={3072} bsPrefix='ia'>{"Thorough"}</Button>
            </ButtonGroup>
        </div>
    );
};
