//  
//  

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Content from "./pages/content/Content";
import { getApplicationConfig } from "./api";

initializeIcons();

export default function App() {
    const [Config, setConfig] = useState<any | null>(null);

    async function fetchApplicationConfig() {
        try {
            const v = await getApplicationConfig();
            if (!v) {
                return null;
            }
            setConfig(v);
            document.title = v?.response.title
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }

    useEffect(() => {
        fetchApplicationConfig();
    }, []);

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout title={Config?.response.title} themeColor={Config?.response.top_ribbon_color}/>}>
                    <Route index element={<Chat envConfig={Config?.response}/>} />
                    <Route path="content" element={<Content />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
